import {
  myAlarmVo,
  myPageBoardContentsVo,
  MyPageControllerService,
  myPageQnaVo,
  myPageMainRegularVo,
  MySupportInfoControllerService,
  FoBannerControllerService,
  FoBannerVo,
  ChildParticipationControllerService,
} from '@/__generated__/FrontApi';
import IconOn from '@/assets/img/ico_accordion_on@2x.png';
import MonthNot from '@/assets/img/icons/dashboard_month1@2x.png';
import MonthOk from '@/assets/img/icons/dashboard_month2@2x.png';
import MonthNotyet from '@/assets/img/icons/dashboard_month3@2x.png';
import Bn1 from '@/assets/img/tmp/mypage/dashboard_bn1@2x.jpg';
import Bn1M from '@/assets/img/tmp/mypage/dashboard_bn1M@2x.jpg';
import Donate2 from '@/assets/img/tmp/mypage/dashboard_donate_bg@2x.png';
import Donate2M from '@/assets/img/tmp/mypage/dashboard_donate_bgM@2x.png';
import DonateObj from '@/assets/img/tmp/mypage/dashboard_donate_obj@2x.png';
import DonateTmpImg from '@/assets/img/tmp/mypage/dashboard_donate_tmp@2x.png';
import Report2 from '@/assets/img/tmp/mypage/dashboard_report2@2x.png';
import Report3 from '@/assets/img/tmp/mypage/dashboard_report3@2x.png';
import Sponsorship from '@/assets/img/tmp/mypage/dashboard_Sponsorship@2x.jpg';
import SponsorshipM from '@/assets/img/tmp/mypage/dashboard_SponsorshipM@2x.jpg';
import UnisafeObj from '@/assets/img/tmp/mypage/dashboard_unisafe@2x.png';
import NoDonorFullBanner from '@/assets/img/tmp/mypage/mypage-full-banner.jpg';
import Report1 from '@/assets/img/tmp/mypage/mypage_report_2020.jpg';
import DonateImgMob from '@/assets/img/tmp/mypage/rectangle@2x.png';
import Button from '@/components/Button';
import ButtonMoreView from '@/components/ButtonMoreView';
import CampaignCard, { CampaignData } from '@/components/Card/CampaignCard';
import Container from '@/components/Container';
import EmergencyPopup from '@/components/EmergencyPopup';
import ErrorMessage from '@/components/Form/ErrorMessage';
import GaugeChart from '@/components/GaugeChart';
import Image from '@/components/Image';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio from '@/components/Input/InputRadio';
import RegularAmountChangeLayerPopup, {
  RegularAmountModifyResult,
} from '@/components/Layer/RegularAmountChangeLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import Section from '@/components/Section';
import SupportPhraseSwiper from '@/components/SupportPhraseSwiper';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { Tit } from '@/components/Titles';
import { breakpoint, breakpointReverse } from '@/helpers/BreakpointHelper';
import {
  isIE,
  isMobileDevice,
  useWindowSizeCustom,
} from '@/helpers/BrowserHelper';
import GtmHelper from '@/helpers/GtmHelper';
import {
  SUPPORT_CATEGORY_REGULAR_WORLD,
  SUPPORT_CATEGORY_REGULAR_AIDS,
  SUPPORT_CATEGORY_REGULAR_REFUGEE,
  SUPPORT_TERM_REGULAR,
  useFindSupportCategory,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import MypageRoutes from '@/page-blocks/mypage/MypageRoutes';
import QuizCard from '@/page-blocks/mypage/QuizCard';
import WhatWeDoPhrase from '@/page-blocks/mypage/WhatWeDoPhrase';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { RouteComponentProps } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as ToLink } from 'react-scroll';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// CSS
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';
import dayjs from 'dayjs';
import Select from '@/components/Select';
import BannerMob from '@/assets/img/banner/img_online_fieldtrip_banner_640.jpg';
import reactStringReplace from 'react-string-replace';
import ChildHistory from './child-history-card';

const reportData = [
  {
    id: 1,
    image: Report1,
    link: `/what-we-do/database?category=KW03#tagBox`,
    tit: `연차보고서`,
  },
  {
    id: 2,
    image: Report2,
    link: `/what-we-do/news`,
    tit: `유니세프 소식`,
  },
  {
    id: 3,
    image: Report3,
    link: `/what-we-do/database?category=KW02#tagBox`,
    tit: `후원자 소식지`,
  },
];

const Formflex = styled.div`
  display: flex;
  align-items: center;

  .col-left {
    width: calc(100% - 136px);
    padding-right: 15px;
  }

  .toggle-flex {
    display: flex;
    margin-bottom: 16px;

    & > li {
      width: 33.33%;
      margin-left: -1px;

      .btn {
        transition: 0.15s;

        &:hover {
          background-color: #fff;
          color: #1cabe2;
        }
      }
    }
  }

  &.regularSupport_form {
    .toggle-flex > li {
      width: auto !important;
      flex: 1 1 auto;
    }
  }
`;

const ValueSlide = styled.article`
  display: flex;
  align-items: stretch;
  margin-bottom: 64px;

  .col-left {
    width: 33.33%;

    .dec-wrap {
      position: relative;
      background: url(${Bn1}) center no-repeat;
      background-size: cover;
      padding-top: 106.82%;

      .dec {
        line-height: 2;
        width: 100%;
        position: absolute;
        padding: 40px 48px;
        left: 0;
        top: 0;
        color: #fff;
      }
    }

    .c-yellow {
      color: #ffdc0e;
    }
  }

  .col-right {
    width: 66.66%;
    background: #fff;
    padding: 38px 0;
  }

  .toggle-flex {
    display: flex;
    margin-bottom: 16px;

    & > li {
      width: 33.33%;
    }
  }

  ${Button} {
    width: 100%;
    display: block;
    height: 136px;
    line-height: 136px;
    border-radius: 100%;
    text-align: center;
    color: #2d2926;
    font-size: 20px;
    font-weight: 700;
  }

  ${breakpoint(1160)} {
    .col-left {
      .dec-wrap {
        .dec {
          padding: 3.45vw 4.14vw;
        }
      }
    }

    .col-right {
      padding: 3.28vw 0;
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;
    margin-bottom: 24px;

    .col-left,
    .col-right {
      width: 100%;
    }

    .col-left {
      .dec-wrap {
        padding-top: 56.25%;
        background: url(${Bn1M}) center no-repeat;
        background-size: cover;
      }
    }

    .col-right {
      padding: 30px 24px 40px;
    }
  }
`;
const ArticleMonth = styled.article`
  background: #fff;
  padding: 48px 75px;
  border-radius: 8px;
  box-shadow: 8px 11px 32px 3px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 0 0 0 40px;
    position: absolute;
    right: 0;
    top: 0;
    background: #2589c9;
  }

  .mont-container {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #e5e6e8;
    padding-bottom: 48px;

    .col-left {
      width: 33.33%;
      padding: 0 20px;
      text-align: center;

      .dec {
        display: inline-block;
        text-align: left;
      }

      .c-blue {
        color: #1cabe2;
      }
    }

    .col-right {
      width: 66.66%;
    }
  }

  .month-flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 64px;

    & > li {
      width: 16.66%;
      text-align: center;
      margin-bottom: 10px;

      .price {
        margin-top: 9px;
        padding: 0 10px;
        color: #1cabe2;
        line-height: 32px;
        display: inline-block;

        &.t2 {
          color: #828385;
          border-radius: 32px;
          background: #e5e6e8;
        }
      }
    }
  }

  .month-item {
    position: relative;
    width: 80px;
    height: 92px;
    display: inline-block;
    padding-top: 52px;
    color: #b7b8ba;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-image: url(${MonthNotyet});

    &._ok {
      color: #fff;
      background-image: url(${MonthOk});
    }

    &._not {
      color: #fff;
      background-image: url(${MonthNot});
    }
  }

  .tip-flex {
    margin-top: 24px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    color: #828385;
    font-size: 14px;

    & > li {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  ${breakpoint(1160)} {
    padding: 48px 6.47vw;
  }

  ${breakpoint(1000)} {
    padding: 48px 20px;

    .month-item {
      position: relative;
      width: 8vw;
      height: 9.2vw;
      padding-top: 5.2vw;
    }
  }

  ${breakpoint(900)} {
    .month-flex {
      & > li {
        width: 33.33%;
        margin-bottom: 32px;
      }
    }

    .month-item {
      position: relative;
      width: 72px;
      height: 83px;
      padding-top: 50px;
    }

    .tip-flex {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: left;

      & > li {
        width: 100%;
        margin: 0;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    &::after {
      width: 28px;
      height: 28px;
    }

    .mont-container {
      flex-wrap: wrap;
      margin-top: 46px;
      padding-bottom: 16px;

      .col-left,
      .col-right {
        width: 100%;
      }
    }
  }

  ${breakpoint(640)} {
    padding: 24px 32px;

    .mont-container {
      margin-top: 0;
    }

    .highcharts-container {
      margin-top: 0px !important;
      height: 60vw !important;
    }

    .month-flex {
      margin-top: 0;
    }
  }

  ${breakpoint(520)} {
    .highcharts-container {
      height: 70vw !important;
    }
  }

  ${breakpoint(420)} {
    .highcharts-container {
      margin-top: -40px !important;
      height: 76vw !important;
    }
  }

  ${breakpoint(375)} {
    .highcharts-container {
      margin-top: -62px !important;
      // height: 270px !important;
    }
  }

  ${breakpoint(330)} {
    .highcharts-container {
      height: 80vw !important;
    }
  }
`;
// eslint-disable-next-line camelcase
const DonateTmp = styled.div`
  position: relative;
  background: #1cabe2;

  ${breakpoint(`mobile`)} {
    padding-bottom: 120px;

    .input-amount {
      display: block;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 1425px;
    height: 496px;
    background: #e2f0f6;
    background-size: cover;
    position: absolute;
    right: 96px;
    bottom: -107px;
    z-index: -1;
  }

  &::after {
    content: '';
    display: block;
    width: 287px;
    height: 436px;
    background: url(${DonateObj}) center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: -43px;
  }

  .col-flex {
    display: flex;
    align-items: flex-start;
    padding: 48px;
    padding-left: 0;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      width: 410px;
      height: 435px;
      background: url(${DonateTmpImg}) center no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      padding-left: 55px;
    }

    .c-blue {
      color: #b2def1;
    }

    ${Tit} {
      letter-spacing: -1.2;

      strong {
        color: #ffdc0e;
      }
    }

    & > .col-left {
      width: 39%;
      padding-left: 62px;

      .dec {
        color: #fff;
        margin-top: 20px;
      }
    }

    & > .col-right {
      width: calc(100% - 39%);

      ${Tit} {
        letter-spacing: -1.2;
        padding-bottom: 32px;
        width: 100%;
        display: block;
        border-bottom: 1px solid #72c9eb;
      }

      .dl-wrap {
        margin-top: 26px;

        ${Button} {
          width: 136px;
          height: 136px;
          border-radius: 100%;
        }

        dl {
          line-height: 2;
          color: #fff;

          dt {
            display: inline-block;
            margin-right: 16px;
            font-weight: 700;
          }

          dd {
            display: inline-block;
          }

          &.d2 {
            dt,
            dd {
              display: block;
              width: 100%;
              margin: 0;
            }

            dd {
              margin-top: 8px;
            }
          }
        }
      }

      .tip {
        color: #e5e6e8;
        font-size: 14px;
        margin-top: 10px;

        ${breakpoint(640)} {
          font-size: 12px;
        }
      }
    }
  }

  ${breakpoint(1160)} {
    &::before {
      bottom: -9.22vw;
    }

    &::after {
      width: 24.74vw;
      height: 37.59vw;
      top: -3.71vw;
    }

    .col-flex {
      padding: 4.14vw;
      padding-left: 0;

      &:after {
        content: '';
        display: block;
        width: 35.34vw;
        height: 37.5vw;
      }

      & > .col-left {
        padding-left: 5.34vw;

        .dec {
          margin-top: 20px;
        }
      }

      & > .col-right {
        ${Tit} {
          padding-bottom: 2.76vw;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    &::before,
    &::after {
      display: none;
    }

    .col-flex {
      padding: 24px 32px;
      height: 512px;

      &::after {
        width: 211px;
        height: 224px;
        left: auto;
        right: 0;
        bottom: -120px;
      }

      & > .col-left,
      .dl-wrap {
        display: none;
      }

      & > .col-right {
        width: 100%;

        ${Tit} {
          border-bottom: 0;
        }
      }

      ${Button} {
        position: absolute;
        z-index: 1;
        width: calc(100% - 48px);
        left: 24px;
        right: 24px;
        bottom: -82px;
      }
    }
  }

  &.t2 {
    padding-top: 27.05%;
    height: 0;
    background: url(${Donate2}) left center no-repeat;
    background-size: cover;
    position: relative;

    &::after {
      display: none;
    }

    &::before {
      height: 296px;
    }

    .col-wrap {
      position: absolute;
      left: 338px;
      right: 32px;
      top: 50%;
      transform: translate(0, -50%);

      .dec {
        color: #fff;
        margin-top: 16px;
        margin-bottom: 30px;
        line-height: 1.7;
      }

      .c-sky {
        color: #b2def1;
      }

      .c-yellow {
        color: #ffdc0e;
      }
    }

    ${Formflex} {
      align-items: flex-start;

      .col-left {
        width: 71%;
        padding: 0;
      }

      .col-right {
        width: 29%;
        padding-left: 16px;

        ${Button} {
          height: auto;
          line-height: 1.4;
          padding: 13px 10px;
        }
      }
    }

    .toggle-flex {
      margin-bottom: 0;
      justify-content: space-around;

      & > li {
        width: auto;
        max-width: 100%;
        width: 27.5%;

        &:first-child {
          border-left: 1px solid #fff;
        }

        .chk-label {
          white-space: nowrap;
          max-width: 100%;

          .btn {
            height: auto;
            line-height: 1.4;
            padding: 13px 0;
            border-left: none;
          }
        }

        &:last-child {
          width: 45%;
        }
      }
    }

    ${breakpoint(1160)} {
      &::before {
        height: 296px;
      }

      .col-wrap {
        left: 29.14vw;
        right: 2.76vw;

        .dec {
          color: #fff;
          margin-top: 1.38vw;
          margin-bottom: 2.59vw;
        }
      }

      ${Formflex} {
        .col-right {
          ${Button} {
            font-size: 1.72vw;
          }
        }
      }

      .toggle-flex {
        & > li {
          .chk-label {
            .txt {
              font-size: 1.72vw;
            }
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      padding: 24px 32px;
      height: 342px;
      background: #1cabe2 url(${Donate2M}) left bottom no-repeat;
      background-size: auto 100%;

      .col-wrap {
        left: auto;
        right: auto;
        top: auto;
        transform: translate(0, 0);
        position: relative;

        .dec {
          color: #fff;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }

      ${Formflex} {
        display: none;
      }

      .btn-wrap {
        position: absolute;
        left: 32px;
        right: 32px;
        bottom: 32px;
      }
    }
  }
`;

const Donate = styled.div<{
  increaseImgPc: string;
  increaseImgMobile: string;
  textLine: number;
}>`
  position: relative;
  background: #1cabe2;

  &::before {
    content: '';
    display: block;
    width: 1425px;
    height: 496px;
    background: #e2f0f6;
    background-size: cover;
    position: absolute;
    right: 96px;
    bottom: -107px;
    z-index: -1;
  }

  &::after {
    content: '';
    display: block;
    width: 296px;
    height: 436px;
    background: url(${DonateObj}) center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: -43px;
  }

  .replaceContent2 {
    min-height: 70px;

    ${breakpoint(640)} {
      min-height: 0px;
    }
  }

  .col-flex {
    display: flex;
    align-items: flex-start;
    padding: 48px;
    padding-left: 0;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: block;
      width: 390px;
      height: 375px;
      ${(props) => `
        background: url(${props.increaseImgPc}) center no-repeat;
        @media only screen and (max-width: 640px) {
          background: url(${props.increaseImgMobile}) center no-repeat;
          margin-bottom: 21%;
        };
        @media only screen and (max-width: 991px) {
          background: url(${props.increaseImgPc}) center no-repeat;
        };
      `}
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }

    .c-blue {
      color: #b2def1;
    }

    ${Tit} {
      letter-spacing: -1.2;

      strong {
        color: #ffdc0e;
      }
    }

    & > .col-left {
      width: 39%;
      padding-left: 62px;

      .dec {
        color: #fff;
        margin-top: 20px;
      }
    }

    & > .col-right {
      width: calc(100% - 39%);

      ${Tit} {
        letter-spacing: -1.2;
        padding-bottom: 32px;
        width: 100%;
        display: block;
        border-bottom: 1px solid #72c9eb;
      }

      .dl-wrap {
        margin-top: 26px;

        ${Button} {
          width: 136px;
          height: 136px;
          border-radius: 100%;
        }

        dl {
          line-height: 2;
          color: #fff;

          dt {
            display: inline-block;
            margin-right: 16px;
            font-weight: 700;
          }

          dd {
            display: inline-block;
          }

          &.d2 {
            dt,
            dd {
              display: block;
              width: 100%;
              margin: 0;
            }

            dd {
              margin-top: 8px;
            }
          }
        }
      }

      .tip {
        color: #e5e6e8;
        font-size: 14px;
        margin: 10px 0 30px 0;

        ${breakpoint(640)} {
          font-size: 12px;
        }
      }
    }
  }

  ${breakpoint(1160)} {
    &::before {
      bottom: -9.22vw;
    }

    &::after {
      width: 24.74vw;
      height: 37.59vw;
      top: -3.71vw;
    }

    .col-flex {
      padding: 4.14vw;
      padding-left: 0;

      &:after {
        content: '';
        display: block;
        width: 35.34vw;
        height: 37.5vw;
      }

      & > .col-left {
        padding-left: 5.34vw;

        .dec {
          margin-top: 20px;
        }
      }

      & > .col-right {
        ${Tit} {
          padding-bottom: 2.76vw;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    &::before {
    }

    &::after {
      display: block;
      // width: 24.74vw;
      // height: 37.59vw;
      // top: -3.71vw;
      ${breakpointReverse('mobile')} {
        width: 90%;
        background-size: 90% 100%;
        background-position: left;
      }
      width: 100%;
      background-size: 100% 100%;
      height: 221px;
      left: 0;
      bottom: 0;
      top: inherit;
      background-image: url(${DonateImgMob});
    }

    .col-flex {
      padding: 24px 32px;
      height: ${(props) => (props.textLine <= 6 ? '512px' : '652px')};
      &::after {
        width: 60%;
        height: 40%;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: contain;
        background-position: bottom;
      }

      & > .col-left,
      .dl-wrap {
        display: none;
      }

      & > .col-right {
        width: 100%;

        ${Tit} {
          border-bottom: 0;
        }
      }

      ${Button} {
        position: absolute;
        z-index: 1;
        width: calc(100% - 48px);
        left: 24px;
        right: 24px;
        bottom: 32px;
      }
    }
  }

  &.t2 {
    padding-top: 27.05%;
    height: 0;
    background: url(${Donate2}) left center no-repeat;
    background-size: cover;
    position: relative;

    &::after {
      display: none;
    }

    &::before {
      height: 296px;
    }

    .col-wrap {
      position: absolute;
      left: 338px;
      right: 32px;
      top: 50%;
      transform: translate(0, -50%);

      .dec {
        color: #fff;
        margin-top: 16px;
        margin-bottom: 30px;
        line-height: 1.7;
      }

      .c-sky {
        color: #b2def1;
      }

      .c-yellow {
        color: #ffdc0e;
      }
    }

    ${Formflex} {
      align-items: flex-start;

      .col-left {
        width: 71%;
        padding: 0;
      }

      .col-right {
        width: 29%;
        padding-left: 16px;

        ${Button} {
          height: auto;
          line-height: 1.4;
          padding: 13px 10px;
        }
      }
    }

    .toggle-flex {
      margin-bottom: 0;
      justify-content: space-around;

      & > li {
        width: auto;
        max-width: 100%;
        width: 27.5%;

        &:first-child {
          border-left: 1px solid #fff;
        }

        .chk-label {
          white-space: nowrap;
          max-width: 100%;

          .btn {
            height: auto;
            line-height: 1.4;
            padding: 13px 0;
            border-left: none;
          }
        }

        &:last-child {
          width: 45%;
        }
      }
    }

    ${breakpoint(1160)} {
      &::before {
        height: 296px;
      }

      .col-wrap {
        left: 29.14vw;
        right: 2.76vw;

        .dec {
          color: #fff;
          margin-top: 1.38vw;
          margin-bottom: 2.59vw;
        }
      }

      ${Formflex} {
        .col-right {
          ${Button} {
            font-size: 1.72vw;
          }
        }
      }

      .toggle-flex {
        & > li {
          .chk-label {
            .txt {
              font-size: 1.72vw;
            }
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      padding: 24px 32px;
      height: 342px;
      background: #1cabe2 url(${Donate2M}) left bottom no-repeat;
      background-size: auto 100%;

      .col-wrap {
        left: auto;
        right: auto;
        top: auto;
        transform: translate(0, 0);
        position: relative;

        .dec {
          color: #fff;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }

      ${Formflex} {
        display: none;
      }

      .btn-wrap {
        position: absolute;
        left: 32px;
        right: 32px;
        bottom: 32px;
      }
    }
  }
`;

const SwiperTogether = styled(SwiperBasic)`
  padding: 0;

  .swiper-container {
    margin: 0 -16px;
  }

  .swiper-slide {
    width: 25%;
    padding: 0 16px;

    .img-wrap {
      position: relative;

      .img-box {
        padding-top: 78.13%;
        border: 1px solid #e5e6e8;
        background-size: 91%;
      }

      ${ButtonMoreView} {
        width: 100%;
      }
    }

    &:hover {
      ${ButtonMoreView} {
        background-color: #374ea2;
        border-color: #374ea2;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-scrollbar {
    display: none;
  }

  ${breakpoint(1160)} {
    .swiper-container {
      margin: 0 -1.38vw;
    }

    .swiper-slide {
      padding: 0 1.38vw;
    }
  }

  ${breakpoint(`tablet`)} {
    .swiper-scrollbar {
      display: block;
      left: 0;
      right: 0;
    }

    .swiper-container {
      margin: 0;
    }

    .swiper-slide {
      width: 320px;
      padding: 0;
      margin-right: 32px;
    }
  }
`;
const SwiperReport = styled(SwiperBasic)`
  padding: 0;

  .swiper-container {
    margin: 0 -58px;
  }

  .swiper-slide {
    width: 33.33%;
    padding: 0 55px;

    .img-wrap {
      position: relative;

      .img-box {
        padding-top: 136.15%;
      }

      ${ButtonMoreView} {
        width: 90%;
        max-width: 256px;
        position: absolute;
        right: 0;
        bottom: -28px;
      }
    }

    &:hover {
      ${ButtonMoreView} {
        background-color: #374ea2;
        border-color: #374ea2;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-scrollbar {
    display: none;
  }

  ${breakpoint(1160)} {
    .swiper-container {
      margin: 0 -5vw;
    }

    .swiper-slide {
      padding: 0 5vw;
    }
  }

  ${breakpoint(`tablet`)} {
    .swiper-scrollbar {
      display: block;
      left: 0;
      right: 0;
    }

    .swiper-container {
      margin: 0;
    }

    .swiper-slide {
      width: 296px;
      padding: 0;
      margin-right: 32px;

      .img-wrap {
        padding-bottom: 80px;

        ${ButtonMoreView} {
          width: 100%;
          max-width: 100%;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
`;
const SectionTop = styled(Section)`
  background: #f8f9fa;
  padding-top: 70px !important;

  ${breakpoint(`tablet`)} {
    padding-top: 35px !important;
  }

  .donate-container {
    margin-bottom: 64px;

    ${breakpoint(`mobile`)} {
      overflow: hidden;
    }
  }

  .b-info {
    font-size: 18px;

    ${breakpoint(`mobile`)} {
      font-size: 13px;
    }
  }

  .b-blue {
    background-color: #2589c9;

    ${breakpoint(`mobile`)} {
      font-size: 13px;
    }
  }
`;
const SectionDonate = styled(Section)`
  article {
    margin-bottom: 240px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      padding-bottom: 60px;
    }
  }

  .bn-slide {
    .swiper-slide {
      width: 100%;

      .img-box {
        padding-top: 19.64%;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    article {
      margin-bottom: 120px;

      h3 {
        padding-bottom: 48px;
      }
    }

    .bn-slide {
      .swiper-scrollbar {
        display: block;
        left: 0;
        right: 0;
      }
    }
  }
`;

const CardContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  margin-top: 50px;
  align-items: stretch;

  & > li {
    padding: 0 16px;
  }

  .col1 {
    width: 66.66%;
    margin-bottom: 64px;
  }

  .col2 {
    width: 33.33%;
    min-height: 370px;
    margin-bottom: 64px;

    &.l5 .col-split {
      height: 195px;
      overflow: hidden;

      ${breakpoint(`tablet`)} {
        height: auto;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        picture,
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .card {
    background: #fff;
    height: 100%;
    position: relative;
    border-radius: 4px;
    box-shadow: 8px 11px 32px 3px rgba(0, 0, 0, 0.04);
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 0 0 0 40px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .link-item {
      color: #1cabe2;
      text-decoration: underline;
      display: inline-block;
    }

    .c-blue {
      color: #1cabe2;
    }

    ${ButtonMoreView} {
      line-height: 42px;
      height: 44px;
      width: 100%;
      max-width: 157px;
      font-size: 16px;
      padding-left: 16px;
    }

    &.c1 {
      padding: 40px 48px;

      &::after {
        background: #80bd41;
      }

      table {
        border: 0;
        margin-top: 14px;

        th,
        td {
          border: 0;
          text-align: left;
          border-bottom: 1px solid #e5e6e8;
          padding: 9px 0 4px;
          line-height: 2;
          height: auto;

          .date {
            color: #828385;
          }

          .no-data {
            border-bottom: none;
          }
        }
      }
    }

    &.c2 {
      padding: 40px 48px;

      &::after {
        background: #b2def1;
      }
    }

    &.c3 {
      padding: 32px 16px;

      &::after {
        background: #ffc20e;
      }

      ${ButtonMoreView} {
        margin-top: 14px;
      }

      .col-top {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-bottom: 1px solid #e5e6e8;
        padding-bottom: 24px;
        text-align: center;

        dl {
          padding: 0 5px;
          width: 50%;
          text-align: center;

          dd {
            margin-top: 8px;
            color: #2d2926;
            font-weight: 700;

            strong {
              margin: 0 8px;
              display: inline-block;
            }
          }
        }

        &.t2 {
          dl {
            width: 100%;
          }
        }

        .link-item {
          margin-top: 24px;
        }
      }

      .col-btt {
        margin-top: 24px;
        text-align: center;

        dd {
          margin-top: 8px;
        }

        .link-item {
          margin-top: 19px;
        }
      }
    }

    &.c4 {
      padding: 32px 16px;
      text-align: center;

      &::after {
        background: #ffc20e;
      }

      .col-top {
        padding-bottom: 24px;
        border-bottom: 1px solid #e5e6e8;
      }

      .col-btt {
        margin-top: 24px;

        ul {
          margin-top: 14px;

          li {
            text-align: left;
            line-height: 2;
            padding: 9px 0px 4px;
            border-bottom: 1px solid rgba(229, 230, 232, 0.5);

            &.no-data {
              text-align: center;
              border-bottom: 0;
            }

            a {
              display: inline-block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-break: break-all;
            }
          }
        }
      }

      .col-flex.qna {
        margin-top: 24px;
        display: flex;
        align-items: center;

        & > .col-item {
          width: 50%;
          text-align: center;

          dd {
            line-height: 2;
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    margin-top: 32px;

    .col1,
    .col2 {
      margin-bottom: 25px;
      width: 100%;
      min-height: auto;
    }

    .card {
      &::after {
        width: 28px;
        height: 28px;
        border-radius: 0 0 0 28px;
      }

      &.c1 {
        padding: 24px 32px;
      }

      &.c2 {
        padding: 24px 32px;

        .col-flex.qna {
          margin-top: 16px;
        }
      }

      &.c3 {
        padding: 24px 32px;
      }

      &.c4 {
        // padding: 24px 32px;
        text-align: center;
        padding: 0;
        background: transparent;
        box-shadow: none;
        overflow: visible;

        &::after {
          display: none;
        }

        & > div {
          background: #fff;
          padding: 24px 32px;
          border-radius: 4px;
          box-shadow: 8px 11px 32px 3px rgba(0, 0, 0, 0.04);
          overflow: hidden;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 0 0 0 28px;
            position: absolute;
            top: 0;
            right: 0;
          }

          &.col-top {
            border-bottom: 0;

            &::after {
              background: rgb(178, 222, 241);
            }
          }

          &.col-btt {
            &::after {
              background: rgb(128, 189, 65);
            }
          }
        }
      }

      &.c5 {
        padding: 11px;

        .col-quiz {
          padding: 45px 16px 76px;
          min-height: 320px;

          .btt-link {
            bottom: 36px;
          }
        }
      }
    }
  }
`;

const IncreaseText = styled.div`
  ${breakpoint(`tablet`)} {
    position: absolute;
    right: 35%;
    bottom: 17%;
    text-align: right;
  }
  ${breakpoint(`mobile`)} {
    position: absolute;
    right: 12%;
    bottom: 100px;
    text-align: right;
  }
`;

const scrollToSectionDonate = () => {
  // Donation.current.scrollIntoView({ behavior: `smooth` });

  const y =
    document.querySelector(`#upgrade`).getBoundingClientRect().top +
    window.pageYOffset +
    -(isMobileDevice() ? 70 : 160);

  if (isIE()) {
    document.querySelector(`#upgrade`).scrollIntoView({
      behavior: `smooth`,
    });
  } else {
    window.scrollTo({ top: y, behavior: `smooth` });
  }
};

SwiperCore.use([Pagination]);

interface RegularSupportStartFormData {
  supportCategory: string;
}

interface IncreaseCardData {
  title: string;
  content1: string;
  content2: string;
  content1Mobile: string;
  content2Mobile: string;
  krwCount: string;
  unit: string;
  attGrpNoPc: string;
  attGrpNoMobile: string;
  totalAmount: string;
  amount1: string;
  amount2: string;
  amount3: string;
  imgAttGrpNoPc: string;
  imgAttGrpNoMobile: string;
  imgNm: string;
}

// 정기후원 시작하기
const RegularSupportStartForm = observer(() => {
  const userStore = useUserStore();
  const { register, handleSubmit } = useForm<RegularSupportStartFormData>({
    defaultValues: {
      supportCategory: SUPPORT_CATEGORY_REGULAR_WORLD,
    },
  });

  const onSubmit: SubmitHandler<RegularSupportStartFormData> = useCallback(
    (formData) => {
      navigate(
        `/donate/?&supportTerm=${SUPPORT_TERM_REGULAR}&supportCategory=${formData.supportCategory}`,
      );
    },
    [],
  );

  if (!userStore.user) {
    return null;
  }
  return (
    <article>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Donate className="t2">
            <div className="col-wrap">
              <Tit size="s4" color="yellow">
                정기후원 시작하기
              </Tit>
              <p className="dec">
                <strong className="c-sky">{userStore.user.name}</strong>
                {` `}
                후원자님의 현재 후원 금액은{` `}
                <strong className="c-yellow">
                  {userStore.user.accumTotalAmount.toLocaleString()}원
                </strong>
                입니다. <br />
                정기후원을 통해 유니세프와 함께해 주세요. 따뜻한 손길을
                나눠주세요.
              </p>

              <Formflex className="regularSupport_form">
                <div className="col-left">
                  <ul className="toggle-flex">
                    <li>
                      <InputRadio
                        ref={register}
                        name="supportCategory"
                        tab
                        label="세계 어린이 돕기"
                        value={SUPPORT_CATEGORY_REGULAR_WORLD}
                        outline
                      />
                    </li>
                    <li>
                      <InputRadio
                        ref={register}
                        name="supportCategory"
                        tab
                        label="난민 어린이 돕기"
                        value={SUPPORT_CATEGORY_REGULAR_REFUGEE}
                        outline
                      />
                    </li>
                    <li>
                      <InputRadio
                        ref={register}
                        name="supportCategory"
                        tab
                        label="에이즈 감염 어린이 돕기"
                        value={SUPPORT_CATEGORY_REGULAR_AIDS}
                        outline
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-right">
                  <Button type="submit" color="yellow" full>
                    후원하기
                  </Button>
                </div>
              </Formflex>
            </div>
            {/* 모바일 버튼 */}
            <div className="btn-wrap pc-hide">
              <Button type="submit" color="yellow" full>
                후원하기
              </Button>
            </div>
          </Donate>
        </form>
      </Container>
    </article>
  );
});

// 누적후원액의 소중한 가치
const SupportValueSlide: FC = observer(() => {
  const userStore = useUserStore();

  if (!userStore.user) {
    return null;
  }

  return (
    <ValueSlide>
      <div className="col-left">
        <div className="dec-wrap">
          <p className="dec">
            <span className="c-yellow">{userStore.user.name}</span>
            {` `}후원자님 <br />
            <Tit size="s4" color="white">
              누적후원액의 <br className="small-show" />
              소중한 가치
            </Tit>
          </p>
        </div>
      </div>
      <div className="col-right">
        <SupportPhraseSwiper supportAmount={userStore.user.accumTotalAmount} />
      </div>
    </ValueSlide>
  );
});

interface RegularSupportChangeFormData {
  additionalSupportAmount: string;
  additionalSupportAmountInput: string;
}

// 정기후원 금액변경하기
const RegularSupportChangeForm: FC<{
  regularInfo: myPageMainRegularVo | undefined;
  onSuccess: () => void;
}> = observer(({ regularInfo, onSuccess, location }) => {
  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const [
    currentModifyResult,
    setCurrentModifyResult,
  ] = useState<RegularAmountModifyResult>();
  // 증액 카드 API 데이터
  const [increaseCardData, setIncreaseCardData] = useState<IncreaseCardData>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    errors,
    reset,
  } = useForm<RegularSupportChangeFormData>({
    defaultValues: {
      additionalSupportAmount: increaseCardData?.amount1,
      additionalSupportAmountInput: ``,
    },
    mode: `onSubmit`,
    shouldFocusError: true,
  });

  /* 모바일용 후원증액 배너 관련 */
  const [mobileAdditionalSelect, setMobileAdditionalSelect] = useState(`5000`);

  const handleAdditionalSelect: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setMobileAdditionalSelect(event.target.value);
      setValue(`additionalSupportAmount`, event.target.value);
      if (event.target.value === `-1`) {
        setValue(`additionalSupportAmount`, ``);
      }
    },
    [setValue],
  );

  const additionalSupportAmount = watch(`additionalSupportAmount`, `0`);
  const additionalSupportAmountInput = watch(`additionalSupportAmountInput`);
  const finalAdditionalSupportAmount = useMemo(
    () => Number(additionalSupportAmount || additionalSupportAmountInput || 0),
    [additionalSupportAmount, additionalSupportAmountInput],
  );
  const newSupportAmount = useMemo(
    () => Number(regularInfo?.bill) + finalAdditionalSupportAmount,
    [finalAdditionalSupportAmount, regularInfo?.bill],
  );
  const supportCategory = useFindSupportCategory(currentModifyResult?.glCode);
  const [ignoreAmountValidation, setIgnoreAmountValidation] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const onSubmit = useCallback(async () => {
    try {
      const {
        data,
        resultCode,
      } = await MySupportInfoControllerService.modifyAmountByGiftIdUsingPost({
        amount: newSupportAmount,
        giftId: regularInfo?.giftId,
        incDecYn: `N`,
        challengeYn: `N`, // 새해다짐 챌린지 2022 식별을 위한 변수 추가
      });

      if (resultCode === `success`) {
        setCurrentModifyResult(data);
        onSuccess();
        // setIgnoreAmountValidation(false);
        // reset();
        setMobileAdditionalSelect(`5000`);
      }
    } catch (e) {
      if (e.response) {
        // popupStore.show(`다시 시도해주세요.`);
        // setIgnoreAmountValidation(false);
        reset();
        setMobileAdditionalSelect(`5000`);
      }
    }
  }, [newSupportAmount, onSuccess, regularInfo?.giftId, reset]);

  const loadData = useCallback(() => {
    // 증액 카드 데이터
    MyPageControllerService.getIncreaseCardUsingGet().then(
      ({ data, resultCode }) => {
        if (resultCode === 'success') {
          setIncreaseCardData(data as IncreaseCardData);
        }
      },
    );
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setValue('additionalSupportAmount', increaseCardData?.amount1);
  }, [increaseCardData?.amount1, setValue]);

  useEffect(() => {
    // 정기후원금액 증액결과 넘어오면
    if (currentModifyResult && supportCategory) {
      // GTM 이벤트 발생
      GtmHelper.addPayMoney({
        supportCategory,
        oldAmount: currentModifyResult.originAmount,
        newAmount: currentModifyResult.upgradeAmount,
        text1: document.querySelector(`#upgrade .col-left`).innerText,
        text2: document.querySelector(`#upgrade .col-right .replaceContent2`)
          .innerText,
        imgNm: document.querySelector(`#upgrade .col-right .replaceContent2`)
          .dataset.imgNm,
      });
    }
  }, [currentModifyResult, supportCategory]);

  useEffect(() => {
    if (!dayjs().isBefore(dayjs(`2021-12-21`))) {
      requestAnimationFrame(() => {
        formRef.current?.dispatchEvent(
          new Event(`submit`, { cancelable: true, bubbles: true }),
        );
      });
    }

    if (errors.additionalSupportAmountInput?.type === `isValidRange`) {
      popupStore.show(
        `새해다짐챌린지 캠페인 드림박스 당첨은 추가 5,000원 이상부터 가능합니다.
        그대로 진행하시겠습니까?`,
        {
          onConfirm: (result) => {
            if (result) {
              // setIgnoreAmountValidation(true);
              requestAnimationFrame(() => {
                formRef.current?.dispatchEvent(
                  new Event(`submit`, { cancelable: true, bubbles: true }),
                );
              });
            } else {
              inputRef.current?.focus();
              reset();
            }
          },
          footer: (
            <>
              <Button size="sm" onClick={() => popupStore.onClose(true)}>
                예
              </Button>
              <Button size="sm" onClick={() => popupStore.onClose(false)}>
                아니오
              </Button>
            </>
          ),
        },
      );
    }
  }, [additionalSupportAmount, errors, popupStore, reset]);

  useEffect(() => {
    if (!mobileAdditionalSelect) {
      setMobileAdditionalSelect(`5000`);
    }
  }, [handleAdditionalSelect, mobileAdditionalSelect]);

  if (!userStore.user) {
    return null;
  }

  const hashString = useMemo(
    () => (location?.hash ? `${location?.hash}` : ``),
    [location?.hash],
  );

  const Donation = useRef();

  useEffect(() => {
    if (hashString === `#upgrade`) {
      const timeout = setTimeout(() => {
        scrollToSectionDonate();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [hashString]);

  const increaseContent1 = increaseCardData?.content1;
  const increaseContent2 = increaseCardData?.content2;
  let replacedContent1;
  let replacedContent2;

  replacedContent1 = reactStringReplace(increaseContent1, '\n', () => <br />);

  replacedContent2 = reactStringReplace(increaseContent2, '\n', () => <br />);

  replacedContent2 = reactStringReplace(replacedContent2, '[[이름]]', () => (
    <strong key={1}>{userStore?.user?.name.toLocaleString()}</strong>
  ));

  replacedContent2 = reactStringReplace(replacedContent2, '[[금액]]', () => (
    <strong key={1}>{finalAdditionalSupportAmount.toLocaleString()}원</strong>
  ));

  replacedContent2 = reactStringReplace(replacedContent2, '[[수량]]', () => (
    <strong key={2}>
      {Math.floor(
        finalAdditionalSupportAmount / Number(increaseCardData?.krwCount),
      ).toLocaleString()}
    </strong>
  ));

  replacedContent2 = reactStringReplace(replacedContent2, '[[단위]]', () => (
    <strong key={3}>{increaseCardData?.unit}</strong>
  ));

  replacedContent2 = reactStringReplace(
    replacedContent2,
    '[[현재후원금액]]',
    () => (
      <strong key={3}>
        {regularInfo?.regularTotalAmount
          ? regularInfo?.regularTotalAmount.toLocaleString()
          : '0'}
        원
      </strong>
    ),
  );

  replacedContent2 = reactStringReplace(
    replacedContent2,
    '[[후원금액]]',
    () => (
      <strong key={3}>
        {regularInfo?.regularTotalAmount
          ? (
              regularInfo?.regularTotalAmount + finalAdditionalSupportAmount
            ).toLocaleString()
          : finalAdditionalSupportAmount.toLocaleString()}
        원
      </strong>
    ),
  );

  return (
    <>
      <article className="donate-container">
        {dayjs().isBefore(dayjs(`2021-12-31`)) ? (
          <DonateTmp>
            <div className="col-flex">
              <div className="col-left">
                <Tit size="s4" color="white">
                  2022년 <br />
                  새해다짐하고 <br />
                  <span className="c-blue">어린이의 꿈</span>
                  <br />
                  응원하기
                </Tit>
              </div>
              <div className="col-right">
                <Tit size="s4" color="white">
                  현재 <span className="c-blue">{userStore.user.name}</span>
                  {` `}
                  후원자님의
                  {` `}
                  <br className="pc-hide" />
                  후원 금액은{` `}
                  <strong>{regularInfo?.bill?.toLocaleString()}원</strong>
                  입니다. <br />
                  <strong>
                    {finalAdditionalSupportAmount.toLocaleString()}원
                  </strong>
                  {` `}
                  추가 후원 하시면 매월{` `}
                  <br className="pc-hide" />
                  후원금액이{` `}
                  <strong>{newSupportAmount.toLocaleString()}원</strong>
                  으로 변경됩니다.
                  <br />
                  <span className="b-info">
                    <span className="b-blue">
                      12월 31일까지 5,000원 이상 증액하신 정기후원자 중 100분을
                      추첨
                    </span>
                    해 드림박스를 드립니다!
                  </span>
                </Tit>

                <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                  {/* 모바일 전용 작업 신규 추가 */}
                  <div className="pc-hide">
                    <dl
                      style={{
                        lineHeight: `2`,
                        color: `#fff`,
                      }}
                    >
                      <dt
                        style={{
                          display: `inline-block`,
                          marginRight: `16px`,
                          fontWeight: `700`,
                        }}
                      >
                        후원 종류
                      </dt>
                      <dd style={{ display: `inline-block` }}>
                        {regularInfo?.cdDescription}
                        {regularInfo?.cdDescriptionDetail
                          ? ` [${regularInfo.cdDescriptionDetail}]`
                          : ``}
                      </dd>
                    </dl>
                    <dl
                      className="d2"
                      style={{
                        lineHeight: `2`,
                        color: `#fff`,
                        fontWeight: `700`,
                      }}
                    >
                      <dt>추가 후원금액</dt>
                    </dl>
                    <Select
                      name="additionalSupportAmount"
                      onChange={handleAdditionalSelect}
                    >
                      <option value="0">추가 후원금액 선택</option>
                      <option value="5000">5,000원</option>
                      <option value="10000">10,000원</option>
                      <option value="50000">50,000원</option>
                      <option value="-1">금액 직접입력</option>
                    </Select>
                    <Controller
                      control={control}
                      name="additionalSupportAmountInput"
                      rules={{
                        validate: {
                          isNotEmpty: (value) =>
                            !!additionalSupportAmount || !!value,
                          isValidNumber: (value) =>
                            !!additionalSupportAmount ||
                            (!Number.isNaN(value) && Number(value) > 0),
                          isValidRange: (value) =>
                            !!additionalSupportAmount ||
                            ignoreAmountValidation ||
                            Number(value) >= 5000,
                        },
                      }}
                      // as 방식일 경우 ref의 controller에 덮어씌워지는 문제로 인하여
                      // render 방식으로 대체 (2021.12.06)
                      render={({ ref, ...rest }) =>
                        mobileAdditionalSelect === `-1` ? (
                          <InputNumber
                            css="display:block;"
                            className="additionalSupportAmountMobile"
                            ref={(_ref) => {
                              inputRef.current = _ref;
                              ref = _ref;
                            }}
                            allowNegative={false}
                            onFocus={() =>
                              setValue(`additionalSupportAmount`, ``)
                            }
                            placeholder="금액 직접입력"
                            {...rest}
                          />
                        ) : (
                          <InputNumber
                            css="display:none;"
                            className="additionalSupportAmountMobile"
                            ref={(_ref) => {
                              inputRef.current = _ref;
                              ref = _ref;
                            }}
                            allowNegative={false}
                            onFocus={() =>
                              setValue(`additionalSupportAmount`, ``)
                            }
                            placeholder="금액 직접입력"
                            {...rest}
                          />
                        )
                      }
                    />
                    {errors.additionalSupportAmountInput?.type ===
                      `isNotEmpty` && (
                      <ErrorMessage>후원 금액을 입력해주세요</ErrorMessage>
                    )}
                    {errors.additionalSupportAmountInput?.type ===
                      `isValidNumber` && (
                      <ErrorMessage>올바른 숫자를 입력해주세요</ErrorMessage>
                    )}
                  </div>
                  {/* 모바일 버튼 */}
                  <Button type="submit" color="yellow" className="pc-hide">
                    후원 더하고{` `}
                    <span style={{ color: `#3162af` }}>어린이 꿈</span>
                    {` `}
                    응원하기
                  </Button>
                  <div className="dl-wrap">
                    <dl>
                      <dt>후원 종류</dt>
                      <dd>
                        {regularInfo?.cdDescription}
                        {regularInfo?.cdDescriptionDetail
                          ? ` [${regularInfo.cdDescriptionDetail}]`
                          : ``}
                      </dd>
                    </dl>
                    <dl className="d2">
                      <dt>추가 후원금액</dt>
                      <dd>
                        <Formflex>
                          <div className="col-left">
                            <ul className="toggle-flex">
                              <li>
                                <InputRadio
                                  ref={register}
                                  name="additionalSupportAmount"
                                  tab
                                  label="5,000"
                                  value="5000"
                                  outline
                                  onClick={() =>
                                    setValue(`additionalSupportAmountInput`, ``)
                                  }
                                />
                              </li>
                              <li>
                                <InputRadio
                                  ref={register}
                                  name="additionalSupportAmount"
                                  tab
                                  label="10,000"
                                  value="10000"
                                  outline
                                  onClick={() =>
                                    setValue(`additionalSupportAmountInput`, ``)
                                  }
                                />
                              </li>
                              <li>
                                <InputRadio
                                  ref={register}
                                  name="additionalSupportAmount"
                                  tab
                                  label="50,000"
                                  value="50000"
                                  outline
                                  onClick={() =>
                                    setValue(`additionalSupportAmountInput`, ``)
                                  }
                                />
                              </li>
                            </ul>
                            <Controller
                              control={control}
                              name="additionalSupportAmountInput"
                              rules={{
                                validate: {
                                  isNotEmpty: (value) =>
                                    !!additionalSupportAmount || !!value,
                                  isValidNumber: (value) =>
                                    !!additionalSupportAmount ||
                                    (!Number.isNaN(value) && Number(value) > 0),
                                  isValidRange: (value) =>
                                    !!additionalSupportAmount ||
                                    ignoreAmountValidation ||
                                    Number(value) >= 5000,
                                },
                              }}
                              // as 방식일 경우 ref의 controller에 덮어씌워지는 문제로 인하여
                              // render 방식으로 대체 (2021.12.06)
                              render={({ ref, ...rest }) => (
                                <InputNumber
                                  ref={(_ref) => {
                                    inputRef.current = _ref;
                                    ref = _ref;
                                  }}
                                  allowNegative={false}
                                  onFocus={() =>
                                    setValue(`additionalSupportAmount`, ``)
                                  }
                                  placeholder="금액 직접입력"
                                  {...rest}
                                />
                              )}
                            />
                            {errors.additionalSupportAmountInput?.type ===
                              `isNotEmpty` && (
                              <ErrorMessage>
                                후원 금액을 입력해주세요
                              </ErrorMessage>
                            )}
                            {errors.additionalSupportAmountInput?.type ===
                              `isValidNumber` && (
                              <ErrorMessage>
                                올바른 숫자를 입력해주세요
                              </ErrorMessage>
                            )}
                          </div>
                          <div className="col-right">
                            <Button
                              type="submit"
                              color="yellow"
                              style={{
                                width: `160px`,
                                height: `128px`,
                                borderRadius: `64px`,
                                fontSize: `18px`,
                              }}
                            >
                              후원 더하고
                              <br />
                              <span style={{ color: `#3162af` }}>
                                어린이 꿈
                              </span>
                              <br />
                              응원하기
                            </Button>
                          </div>
                        </Formflex>
                        <p className="tip">
                          * 2건 이상 정기후원 중인 후원자님의 경우 가장 먼저
                          시작한 후원에 추가 후원금액이 반영됩니다.
                          <br />
                          새해다짐챌린지 캠페인은 추가후원 5,000원 이상부터
                          드림박스 당첨이 가능합니다.
                        </p>
                      </dd>
                    </dl>
                  </div>
                </form>
              </div>
            </div>
          </DonateTmp>
        ) : (
          // TODO: 앵커 달고 접속시 이동
          <Donate
            ref={Donation}
            id="upgrade"
            increaseImgPc={increaseCardData?.imgAttGrpNoPc}
            increaseImgMobile={increaseCardData?.imgAttGrpNoMobile}
            textLine={replacedContent2.length}
          >
            <div className="col-flex">
              <div className="col-left">
                <Tit
                  color="white"
                  style={{
                    fontSize: '18px',
                    lineHeight: '1.76',
                  }}
                >
                  <div
                    style={{
                      width: '230px',
                    }}
                  >
                    {replacedContent1}
                  </div>
                </Tit>
              </div>
              <div className="col-right">
                <Tit size="s4" color="white">
                  <div
                    className="replaceContent2"
                    data-img-nm={increaseCardData?.imgNm}
                  >
                    <span>{replacedContent2}</span>
                  </div>
                </Tit>

                <form onSubmit={handleSubmit(onSubmit)}>
                  {useWindowSizeCustom().width <= 991 ? (
                    <>
                      {/* 모바일 전용 작업 신규 추가 */}
                      <div className="pc-hide">
                        <dl
                          style={{
                            lineHeight: `2`,
                            color: `#fff`,
                          }}
                        >
                          <dt
                            style={{
                              display: `inline-block`,
                              marginRight: `16px`,
                              fontWeight: `700`,
                            }}
                          >
                            후원 종류
                          </dt>
                          <dd style={{ display: `inline-block` }}>
                            {regularInfo?.cdDescription}
                            {regularInfo?.cdDescriptionDetail
                              ? ` [${regularInfo.cdDescriptionDetail}]`
                              : ``}
                          </dd>
                        </dl>
                        <dl
                          className="d2"
                          style={{
                            lineHeight: `2`,
                            color: `#fff`,
                            fontWeight: `700`,
                          }}
                        >
                          <dt>추가 후원금액</dt>
                        </dl>
                        <dd>
                          <Formflex>
                            <div
                              className="col-left"
                              style={{
                                paddingRight: 0,
                                width: '100%',
                              }}
                            >
                              <ul className="toggle-flex">
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount1,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount1}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount2,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount2}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount3,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount3}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                          </Formflex>
                        </dd>
                        <Controller
                          control={control}
                          name="additionalSupportAmountInput"
                          rules={{
                            validate: {
                              isNotEmpty: (value) =>
                                !!additionalSupportAmount || !!value,
                              isValidNumber: (value) =>
                                !!additionalSupportAmount ||
                                (!Number.isNaN(value) && Number(value) > 0),
                            },
                          }}
                          // as 방식일 경우 ref의 controller에 덮어씌워지는 문제로 인하여
                          // render 방식으로 대체 (2021.12.06)
                          render={({ ref, ...rest }) => (
                            <InputNumber
                              css="display:block;"
                              className="additionalSupportAmountMobile"
                              ref={(_ref) => {
                                inputRef.current = _ref;
                                ref = _ref;
                              }}
                              allowNegative={false}
                              onFocus={() =>
                                setValue(`additionalSupportAmount`, ``)
                              }
                              placeholder="금액 직접입력"
                              {...rest}
                            />
                          )}
                        />
                        {errors.additionalSupportAmountInput?.type ===
                          `isNotEmpty` && (
                          <ErrorMessage>후원 금액을 입력해주세요</ErrorMessage>
                        )}
                        {errors.additionalSupportAmountInput?.type ===
                          `isValidNumber` && (
                          <ErrorMessage>
                            올바른 숫자를 입력해주세요
                          </ErrorMessage>
                        )}
                      </div>
                      <IncreaseText className="pc-hide">
                        <Tit
                          color="white"
                          style={{
                            fontSize: '13px',
                            lineHeight: '1.76',
                            width: '120px',
                          }}
                        >
                          {replacedContent1}
                        </Tit>
                      </IncreaseText>
                      {/* 모바일 버튼 */}
                      <Button type="submit" color="yellow" className="pc-hide">
                        후원하기
                      </Button>
                    </>
                  ) : (
                    <div className="dl-wrap">
                      <dl>
                        <dt>후원 종류</dt>
                        <dd>
                          {regularInfo?.cdDescription}
                          {regularInfo?.cdDescriptionDetail
                            ? ` [${regularInfo.cdDescriptionDetail}]`
                            : ``}
                        </dd>
                      </dl>
                      <dl className="d2">
                        <dt>추가 후원금액</dt>
                        <dd>
                          <Formflex>
                            <div className="col-left">
                              <ul className="toggle-flex">
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount1,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount1}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount2,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount2}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                                <li>
                                  <InputRadio
                                    ref={register}
                                    name="additionalSupportAmount"
                                    tab
                                    label={Number(
                                      increaseCardData?.amount3,
                                    ).toLocaleString()}
                                    value={increaseCardData?.amount3}
                                    outline
                                    onClick={() =>
                                      setValue(
                                        `additionalSupportAmountInput`,
                                        ``,
                                      )
                                    }
                                  />
                                </li>
                              </ul>
                              <Controller
                                control={control}
                                name="additionalSupportAmountInput"
                                as={
                                  <InputNumber
                                    allowNegative={false}
                                    onFocus={() =>
                                      setValue(`additionalSupportAmount`, ``)
                                    }
                                    placeholder="금액 직접입력"
                                  />
                                }
                              />
                            </div>
                            <div className="col-right">
                              <Button type="submit" color="yellow">
                                후원하기
                              </Button>
                            </div>
                          </Formflex>
                          <p className="tip">
                            * 2건 이상 정기후원 중인 후원자님의 경우 가장 먼저
                            시작한 후원에 추가 후원금액이 반영됩니다.
                          </p>
                        </dd>
                      </dl>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Donate>
        )}
      </article>
      {!!currentModifyResult && (
        <RegularAmountChangeLayerPopup
          result={currentModifyResult}
          isAmountIncrease
          onRequestClose={() => setCurrentModifyResult(undefined)}
        />
      )}
    </>
  );
});

const FloatingBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  width: 80px;
  height: 120px;
  position: fixed;
  right: 7%;
  bottom: 5%;
`;

const FloatingBannerImg = styled.img`
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const FloatingBannerText = styled.div`
  margin-top: 5px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
`;

const IncreaseBannerContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 30px;
`;

const IncreaseBannerImg = styled(Image)`
  width: 100%;
  max-width: 100%;
`;

interface ShortCutData {
  [key: string]: string;
}

// 퀴즈 카드
const MyPage: FC<RouteComponentProps> = observer(({ location }) => {
  const userStore = useUserStore();
  const [notifications, setNotifications] = useState<myAlarmVo[]>([]);
  const [qnaInfo, setQnaInfo] = useState<myPageQnaVo>();
  const [contents, setContents] = useState<myPageBoardContentsVo[]>([]);
  const [regularInfo, setRegularInfo] = useState<myPageMainRegularVo>();
  const [regularHistories, setRegularHistories] = useState<any[]>([]);

  const [shortCutBanner, setShortCutBanner] = useState<ShortCutData>();
  const [banners, setBanners] = useState<CampaignData[]>([]);
  const [quizBanners, setQuizBanners] = useState<CampaignData[]>([]);
  const [noDonorBanners, setNoDonorBanners] = useState<CampaignData[]>([]);
  const [loading, setLoading] = useState(true);

  const [childApplyData, setChildApplyData] = useState([]);

  const dashBoardBanner = useCallback(async () => {
    try {
      // 후원자 대시보드 배너
      if (userStore.isDonor()) {
        const {
          data: mypageQuizBanners,
        } = await FoBannerControllerService.selectBannerListUsingGet({
          kind: '5004',
        });
        setQuizBanners(
          mypageQuizBanners.map((banner: FoBannerVo) => ({
            id: banner.indexNumber,
            pcImage: banner.imgAttGrpNoPc,
            mobileImage: banner.imgAttGrpNoMobile,
            link: banner.targetUrl,
            linkTargetBlank: banner.targetBlankYn === `Y`,
          })),
        );
      } else {
        // 비후원자 대시보드 배너
        const {
          data: mypageNoDonorBanners,
        } = await FoBannerControllerService.selectBannerListUsingGet({
          kind: '5003',
        });
        setNoDonorBanners(
          mypageNoDonorBanners.map((banner: FoBannerVo) => ({
            id: banner.indexNumber,
            pcImage: banner.imgAttGrpNoPc,
            mobileImage: banner.imgAttGrpNoMobile,
            link: banner.targetUrl,
            linkTargetBlank: banner.targetBlankYn === `Y`,
          })),
        );
      }

      setLoading(false);
    } catch (e) {
      console.error(e);
    }

    // 바로가기 배너 get Data
    if (userStore.isDonor()) {
      try {
        const {
          data: shortCutBannerData,
        } = await FoBannerControllerService.shortCutBannerGet();
        setShortCutBanner(shortCutBannerData);
      } catch (e) {
        console.error(e);
      }
    }
  }, [userStore]);

  async function fetchChildApplyStatus() {
    try {
      const result = await ChildParticipationControllerService.getChildApplyMyListUsingGet();

      setChildApplyData(result.data);
    } catch (error) {
      console.error('API Call Failed:', error);
    }
  }

  useEffect(() => {
    dashBoardBanner();
    fetchChildApplyStatus();
  }, [dashBoardBanner]);

  // 정기후원 결제한 월 카운트
  const [payPercentage, setPayPercentage] = useState(0);

  //  데이터 로딩
  const loadData = useCallback(() => {
    if (userStore.isDonor()) {
      // 내 후원 소식
      MyPageControllerService.getMyAlarmListUsingGet().then(
        ({ data, resultCode }) => {
          if (resultCode === `success`) {
            setNotifications(data);
          }
        },
      );
    }

    // 문의 내역
    MyPageControllerService.getMyQnaCountUsingGet().then(
      ({ data, resultCode }) => {
        if (resultCode === `success`) {
          setQnaInfo(data);
        }
      },
    );

    // 대시보드 컨텐츠
    MyPageControllerService.getMyPageDashBoardContentsUsingGet().then(
      ({ data, resultCode }) => {
        if (resultCode === `success`) {
          setContents(data);
        }
      },
    );

    if (userStore.isRegularDonor()) {
      // 정기후원 정보
      MyPageControllerService.getMyDonorInfoUsingGet().then(
        ({ data, resultCode }) => {
          if (resultCode === `success`) {
            setRegularInfo(data);
          }
        },
      );

      // 정기후원 목록
      MyPageControllerService.getMyDonorHistoryUsingGet().then(
        ({ data, resultCode }) => {
          if (resultCode === `success`) {
            setPayPercentage(
              data.completed_cnt &&
                Math.floor(
                  (data.completed_cnt /
                    (data.failed_cnt + data.completed_cnt)) *
                    100,
                ),
            );
            setRegularHistories(data.list);
          }
        },
      );
    }
  }, [userStore]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!userStore.user) {
    return null;
  }

  const hashString = useMemo(
    () => (location?.hash ? `${location?.hash}` : ``),
    [location?.hash],
  );

  const Donation = useRef();

  // const scrollToSectionDonate = () => {
  //   // Donation.current.scrollIntoView({ behavior: `smooth` });

  //   const y =
  //     document.querySelector(`#upgrade`).getBoundingClientRect().top +
  //     window.pageYOffset +
  //     -100;

  //   if (isIE()) {
  //     document.querySelector(`#upgrade`)?.scrollIntoView({
  //       behavior: `smooth`,
  //     });
  //   } else {
  //     window.scrollTo({ top: y, behavior: `smooth` });
  //   }
  // };

  const nextDonateUrl = () => {
    window.location.href = `https://www.unicef.or.kr/campaign/reimagine2022/?trackcode=reimagine2022_mypage_stc`;
  };

  useEffect(() => {
    if (hashString === `#donateSection`) {
      scrollToSectionDonate();
    }
  }, [hashString]);

  const userNameWidth = document.querySelector(`.user-name`)?.clientWidth;

  return (
    <LayoutWithTitle
      location={location}
      title="마이페이지"
      description="for every child, UNICEF"
    >
      <MypageRoutes currentKey={1} />
      <SectionTop className="by-sub-main-layout">
        <Container>
          <Tit size="s2" color="sky" className="user-name">
            {userStore.user.name}
          </Tit>
          {userStore.isDonor() ? (
            <>
              {userStore.isRegularDonor() ? (
                <>
                  <Tit
                    size="s4"
                    css={`
                      display: inline;
                      padding-left: 4px;
                    `}
                  >
                    {shortCutBanner?.title.slice(
                      shortCutBanner?.title.indexOf(' ') + 1,
                    )}
                  </Tit>
                  <Tit
                    onClick={scrollToSectionDonate}
                    css={`
                      background-color: transparent !important;
                      border: 0;
                      outline: 0;
                      white-space: pre-line;

                      &:hover {
                        cursor: pointer;
                      }

                      ${breakpoint(`tablet`)} {
                        display: block;
                      }
                    `}
                  >
                    <div className="pc-hide">
                      <Tit color="sky">{shortCutBanner?.content}</Tit>
                      <Button
                        css={`
                          background: url(${IconOn}) no-repeat center center;
                          background-size: 18px;
                          border: 0;
                          outline: 0;

                          &:hover {
                            border-bottom-color: transparent;
                            background-color: transparent !important;
                            text-decoration: none;
                          }

                          margin-left: 2.5px;
                          transform: rotate(90deg) scale(1.5);
                        `}
                      />
                    </div>
                  </Tit>
                </>
              ) : (
                <Tit
                  size="s4"
                  css={`
                    display: inline;
                    padding-left: 4px;
                  `}
                >
                  {` `}
                  후원자님, 어린이들의 꿈과 미래에 꾸준한 도움이 필요합니다.
                </Tit>
              )}
            </>
          ) : (
            <Tit
              size="s4"
              css={`
                display: inline;
                padding-left: 4px;
              `}
            >
              님, 안녕하세요.
            </Tit>
          )}

          {userStore.isDonor() ? (
            <>
              {/* Mobile Increase FloatingBanner */}
              <ToLink to="increase-card" spy smooth offset={-100}>
                <FloatingBannerContainer className="pc-hide">
                  <FloatingBannerImg src={shortCutBanner?.imgAttGrpNoMobile} />
                  <FloatingBannerText>
                    {shortCutBanner?.moTitle}
                  </FloatingBannerText>
                </FloatingBannerContainer>
              </ToLink>

              {/* PC Increase Banner */}
              <IncreaseBannerContainer className="m-hide">
                <ToLink to="increase-card" spy smooth offset={-180}>
                  <div
                    style={{ position: 'absolute', left: '15%', top: '25%' }}
                  >
                    <Tit size="s4" weight="normal">
                      {shortCutBanner?.content}
                    </Tit>
                    <br />
                    <Tit
                      color="gray"
                      weight="normal"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      #{userStore.user.name} 후원자님 따뜻한 마음을 아동과 함께
                      나누어 주세요.
                      {'     '}
                    </Tit>
                    <Tit
                      style={{
                        textDecoration: 'underline',
                      }}
                    >
                      후원하기
                    </Tit>
                    <Tit style={{ whiteSpace: 'pre-wrap' }}>
                      {'   '}
                      {'>'}
                    </Tit>
                  </div>

                  <IncreaseBannerImg
                    className="card-img"
                    pcSrc={shortCutBanner?.imgAttGrpNoPc}
                    mobileSrc={shortCutBanner?.imgAttGrpNoPc}
                    // src={shortCutBanner?.imgAttGrpNoPc}
                  />
                </ToLink>
              </IncreaseBannerContainer>
            </>
          ) : (
            <IncreaseBannerContainer className="m-hide">
              <LinkSafe
                to="/mypage/support-history/connect"
                className="link-bn m-hide"
              >
                <IncreaseBannerImg
                  className="card-img"
                  pcSrc={NoDonorFullBanner}
                />
              </LinkSafe>
            </IncreaseBannerContainer>
          )}

          {/* 어린이 체험관 신청내역 */}
          {childApplyData ? <ChildHistory data={childApplyData} /> : null}

          <article>
            <CardContainer>
              <li className="col2">
                <div className="card c3">
                  {userStore.isRegularDonor() ? (
                    <div className="col-top">
                      <dl>
                        <dt>
                          <strong>약정일</strong>
                        </dt>
                        <dd>
                          <p className="dec">
                            매월{` `}
                            <Tit size="s2" color="sky">
                              {regularInfo?.payDay}
                            </Tit>
                            일
                          </p>
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <strong>약정금액</strong>
                        </dt>
                        <dd>
                          <p className="dec">
                            <Tit size="s2" color="sky">
                              {regularInfo?.regularTotalAmount?.toLocaleString()}
                            </Tit>
                            원
                          </p>
                        </dd>
                      </dl>
                      <LinkSafe to="/mypage/support-info" className="link-item">
                        결제 정보를 변경하고 싶으신가요?
                      </LinkSafe>
                    </div>
                  ) : (
                    <div className="col-top t2">
                      <dl>
                        <dt>
                          <strong className="dec">
                            매달 어린이를 위한 약속{` `}
                            <span className="c-blue">정기후원,</span>
                            {` `}
                            <br className="m-hide" />
                            어린이는 어떤 도움을 받나요?
                          </strong>
                        </dt>
                        <dd>
                          <ButtonMoreView
                            mode="skyTransparent"
                            onClick={() =>
                              navigate(`/involve/individual/pledge`)
                            }
                          >
                            자세히보기
                          </ButtonMoreView>
                        </dd>
                      </dl>
                    </div>
                  )}

                  {userStore.isDonor() ? (
                    <div className="col-btt">
                      <dl>
                        <dt>
                          <strong className="dec">누적 후원액</strong>
                        </dt>
                        <dd>
                          <p className="dec">
                            <Tit size="s2" color="sky">
                              {userStore.user.accumTotalAmount.toLocaleString()}
                            </Tit>
                            원
                          </p>
                        </dd>
                      </dl>
                      <LinkSafe
                        to="/mypage/support-history"
                        className="link-item"
                      >
                        그동안의 후원내역이 궁금하신가요?
                      </LinkSafe>
                    </div>
                  ) : (
                    <div className="col-btt">
                      <dl>
                        <dt>
                          <strong className="dec">
                            유니세프가 궁금하신가요?
                          </strong>
                        </dt>
                        <dd>
                          <ButtonMoreView
                            onClick={() => navigate(`/faq`)}
                            mode="skyTransparent"
                          >
                            FAQ 바로가기
                          </ButtonMoreView>
                        </dd>
                      </dl>
                    </div>
                  )}
                </div>
              </li>

              <li className="col2">
                <div className="card c4">
                  <div className="col-top">
                    <strong className="tit">최근 문의 내역</strong>
                    <div className="col-flex qna">
                      <LinkSafe to="/mypage/onetoone" className="col-item">
                        <dl>
                          <dt>
                            <Tit size="s2" color="sky">
                              {qnaInfo?.replyWaitCount}
                            </Tit>
                          </dt>
                          <dd>문의 대기</dd>
                        </dl>
                      </LinkSafe>
                      <LinkSafe to="/mypage/onetoone" className="col-item">
                        <dl>
                          <dt>
                            <Tit size="s2" color="gray">
                              {qnaInfo?.replyOkCount}
                            </Tit>
                          </dt>
                          <dd>답변 완료</dd>
                        </dl>
                      </LinkSafe>
                    </div>
                  </div>
                  <div className="col-btt">
                    <strong className="tit">내 후원 소식</strong>
                    <ul>
                      {notifications.length ? (
                        notifications.map((notification) => (
                          <li key={notification.giftId}>
                            <LinkSafe
                              to={notification.targetUrl}
                              className="dec"
                            >
                              {notification.content}
                            </LinkSafe>
                          </li>
                        ))
                      ) : (
                        <li className="no-data">새로운 후원 소식이 없습니다</li>
                      )}
                    </ul>
                  </div>
                </div>
              </li>

              {/* 후원자 / 비후원자 */}
              {userStore.isDonor() ? (
                <li className="col2 l5">
                  {/* 데이터 로딩이 완료된 후에 조건부 렌더링 수행 */}
                  {!loading && (
                    <>
                      {/* 마이페이지 메인 배너가 있으면 2분할 배너 */}
                      {/* 배너가 없으면 퀴즈 카드 */}
                      {quizBanners.length ? (
                        quizBanners.map((banner) => (
                          <div key={banner.id} className="col-split">
                            <Link
                              to={banner.link}
                              target={banner.linkTargetBlank ? '_blank' : '_self'}
                            >
                              <Image
                                pcSrc={banner.pcImage}
                                mobileSrc={banner.mobileImage}
                              />
                            </Link>
                          </div>
                        ))
                      ) : (
                        <QuizCard />
                      )}
                    </>
                  )}
                </li>
              ) : (
                <li className="col2 l5">
                  {/* 데이터 로딩이 완료된 후에 조건부 렌더링 수행 */}
                  {!loading && (
                    <>
                      {noDonorBanners.length > 0 ? (
                        <Link
                          to={noDonorBanners[0].link}
                          target={
                            noDonorBanners[0].linkTargetBlank ? '_blank' : '_self'
                          }
                        >
                          <Image
                            pcSrc={noDonorBanners[0].pcImage}
                            mobileSrc={noDonorBanners[0].mobileImage}
                          />
                        </Link>
                      ) : (
                        <QuizCard />
                      )}
                    </>
                  )}
                </li>
              )}
            </CardContainer>
          </article>

          {userStore.isRegularDonor() && (
            <>
              <SupportValueSlide />

              {/* 정기후원 금액변경 폼 */}
              {userStore.isRegularDonor() && (
                <div ref={Donation} id="increase-card">
                  <RegularSupportChangeForm
                    regularInfo={regularInfo}
                    onSuccess={loadData}
                    location={location}
                  />
                </div>
              )}

              <ArticleMonth>
                <div className="mont-container">
                  <div className="col-left">
                    <strong className="dec">
                      <span className="c-blue">{userStore.user.name}</span>
                      {` `}
                      후원자님의 정기후원 내역
                    </strong>
                    <div>
                      <GaugeChart
                        centerText={`<b style="font-size: 36px;letter-spacing: -1.6px;color:#1cabe2">${payPercentage}%</b><b></b>`}
                        series={[
                          {
                            name: ``,
                            data: [
                              {
                                color: `#1cabe2`,
                                radius: `112%`,
                                innerRadius: `88%`,
                                y: payPercentage,
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-right">
                    <ul className="month-flex">
                      {Array.from({ length: 12 }).map((_, i) => {
                        let className = `_notyet`;
                        // 놓친후원 표시
                        const found = regularHistories.find(
                          (h) => h.giftDateMonth === i + 1,
                        );
                        if (found?.result === `completed`) {
                          className = `_ok`;
                        } else if (found?.result === `mixed`) {
                          className = `_not`;
                        }
                        return (
                          <li key={i}>
                            {found ? (
                              <Link
                                to={`/mypage/support-history?tabIndex=${
                                  found.result === `completed` ? 0 : 1
                                }`}
                              >
                                <strong className={`month-item ${className}`}>
                                  {i + 1}월
                                </strong>
                                <p>
                                  {[`completed`, `mixed`].includes(
                                    found.result,
                                  ) && (
                                    <strong className="price">
                                      {found.monthBill.toLocaleString()}원
                                    </strong>
                                  )}
                                  {found.result === `failed` && (
                                    <strong className="price t2">
                                      놓친 후원
                                    </strong>
                                  )}
                                </p>
                              </Link>
                            ) : (
                              <strong className={`month-item ${className}`}>
                                {i + 1}월
                              </strong>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <ul className="tip-flex">
                  <li>
                    * 후원 완료한 달을 클릭하시면 상세 후원 내역을 확인할 수
                    있습니다.
                  </li>
                  <li>
                    * 후원하지 못한 달을 클릭하시면 놓친 후원을 납부하실 수
                    있습니다.
                  </li>
                </ul>
              </ArticleMonth>
            </>
          )}
        </Container>
      </SectionTop>

      <SectionDonate className="by-sub-main-layout">
        {/* 정기후원 시작하기 폼 */}
        {!userStore.isRegularDonor() && <RegularSupportStartForm />}

        {banners.length > 0 && (
          <article>
            <Container>
              <CampaignCard
                campaigns={banners}
                autoplay
                overflowHidden
                className="bn-slide"
                img
              />
            </Container>
          </article>
        )}

        <article>
          <Container>
            <h3>
              <Tit size="s2">함께해요! 모든 어린이가 행복한 세상</Tit>
            </h3>
            <SwiperTogether>
              <Swiper
                slidesPerView="auto"
                navigation
                scrollbar={{ draggable: true }}
              >
                {contents.map((row) => (
                  <SwiperSlide key={row.indexNumber}>
                    <div className="img-wrap">
                      <LinkSafe
                        to={row.targetUrl}
                        target={row.targetBlankYn === `Y` ? `_blank` : ``}
                      >
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${row.attGrpNo});
                          `}
                        />
                      </LinkSafe>
                      <ButtonMoreView
                        mode="sky"
                        href={row.targetUrl}
                        target={row.targetBlankYn === `Y` ? `_blank` : ``}
                      >
                        {row.title}
                      </ButtonMoreView>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperTogether>
          </Container>
        </article>
        <article>
          <Container>
            <h3>
              <Tit size="s2">유니세프 후원금, 어떻게 쓰일까요?</Tit>
            </h3>
            <SwiperReport>
              <Swiper
                slidesPerView="auto"
                navigation
                scrollbar={{ draggable: true }}
              >
                {reportData.map((row) => (
                  <SwiperSlide key={row.id}>
                    <LinkSafe to={row.link}>
                      <div className="img-wrap">
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${row.image});
                          `}
                        />
                        <ButtonMoreView mode="sky">{row.tit}</ButtonMoreView>
                      </div>
                    </LinkSafe>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperReport>
          </Container>
        </article>
      </SectionDonate>
      <EmergencyPopup kind={2} />
    </LayoutWithTitle>
  );
});

export default MyPage;
